<template>
  <div class="favorites-cnt position-relative width-auto">
    <!-- Edir button that trigger magange favorite poup-->
    <div
      v-if="isEditable && !notManagable"
      class="d-flex align-items-center justify-content-end"
    >
      <span
        data-test-id="edit-favorites"
        class="text-50 d-flex justify-content-center align-items-center text-primary"
        @click="setActivePopUp('edit-locations')"
        ><i class="fi flaticon-edit me-2" />Edit</span
      >
    </div>
    <!-- Carouse that Present Favorite-->
    <div v-if="favorites && teacher">
      <Carousel
        v-if="!favoriteLength"
        :class="classes"
        :navigate-to="[navigateTo, false]"
        :per-page="favoritesPages"
        class="v3favorite-rooms-carousel"
        name="favorite-rooms-carousel"
        :style="'max-width:' + favoritesWidth + ' !important;'"
      >
        <Slide
          v-for="(elem, index) in favorites"
          :key="index"
          :style="'flex-basis:' + favoritesFlexBasis + ' !important;'"
          @slide-click="favoritesNavigateToPage(index)"
        >
          <CCol
            class="v3favorite-container d-flex flex-column align-items-center justify-content-center p-1"
            :class="[current == elem.favorable.id ? 'v3active-container' : '']"
            v-if="elem.favorable && elem.favorable_type == 'App\\Models\\Room'"
          >
            <div
              :class="[
                current == elem.favorable.id ? 'v3active' : '',
                isvalid === false ? 'invalid' : '',
                'v3location-box-room'
              ]"
              @click="selectElement(elem)"
            >
              <div
                :class="`fav-icon ${
                  elem.favorable.icon && elem.favorable.icon != 0
                    ? elem.favorable.icon
                    : 'flaticon-star'
                }`"
              />
            </div>
            <span data-test-id="favorite-text-box" class="v3fav-text">
              {{ elem.favorable.name }}
            </span>
          </CCol>
          <CCol
            class="v3favorite-container d-flex flex-column align-items-center justify-content-center p-1"
            :class="[current == elem.favorable.id ? 'v3active-container' : '']"
            v-if="elem.favorable && elem.favorable_type == 'App\\Models\\User'"
          >
            <div
              :class="[
                current == elem.favorable.id ? 'v3active' : '',
                isvalid === false ? 'invalid' : '',
                { 'active invalid': isUserAvailable(elem) },
                'v3location-box-user'
              ]"
              :style="{
                backgroundImage: `url(${
                  elem.favorable.avatar
                    ? elem.favorable.avatar
                    : '/img/avatars/user-avatar.jpeg'
                })`
              }"
              @click="selectElement(elem)"
            ></div>
            <span data-test-id="favorite-text-box" class="v3fav-text">
              {{ elem.favorable.first_name + " " + elem.favorable.last_name }}
            </span>
          </CCol>
        </Slide>
      </Carousel>
      <Carousel
        v-else
        :class="classes"
        :navigate-to="[navigateTo, false]"
        :per-page="favoritesPages"
        class="v3favorite-rooms-carousel"
        name="favorite-rooms-carousel"
        :style="'max-width:' + favoritesWidth + ' !important;'"
      >
        <Slide
          v-for="(elem, index) in favorites"
          :key="index"
          :style="'flex-basis:' + favoritesFlexBasis + ' !important;'"
          @slide-click="favoritesNavigateToPage(index)"
        >
          <CCol
            class="v3favorite-container d-flex flex-column align-items-center justify-content-center p-1"
            :class="[current == elem.favorable.id ? 'v3active-container' : '']"
            v-if="elem.favorable && elem.favorable_type == 'App\\Models\\Room'"
          >
            <div
              :class="[
                current == elem.favorable.id ? 'v3active' : '',
                isvalid === false ? 'invalid' : '',
                'v3location-box-room'
              ]"
              @click="selectElement(elem)"
            >
              <div
                :class="`fav-icon ${
                  elem.favorable.icon && elem.favorable.icon != 0
                    ? elem.favorable.icon
                    : 'flaticon-star'
                }`"
              />
            </div>

            <span data-test-id="favorite-text-box" class="v3fav-text">
              {{ elem.favorable.name }}
            </span>
          </CCol>
          <CCol
            class="v3favorite-container d-flex flex-column align-items-center justify-content-center p-1"
            :class="[current == elem.favorable.id ? 'v3active-container' : '']"
            v-if="elem.favorable && elem.favorable_type == 'App\\Models\\User'"
          >
            <div
              :class="[
                current == elem.favorable.id ? 'v3active' : '',
                isvalid === false ? 'invalid' : '',
                { 'active invalid': isUserAvailable(elem) },
                'v3location-box-user'
              ]"
              :style="{
                backgroundImage: `url(${
                  elem.favorable.avatar
                    ? elem.favorable.avatar
                    : '/img/avatars/user-avatar.jpeg'
                })`
              }"
              @click="selectElement(elem)"
            ></div>
            <span data-test-id="favorite-text-box" class="v3fav-text">
              {{ elem.favorable.first_name + " " + elem.favorable.last_name }}
            </span>
          </CCol>
        </Slide>
      </Carousel>
    </div>
    <div v-else>
      <Carousel
        :class="classes"
        :navigate-to="[navigateTo, false]"
        :per-page="favoritesPages"
        class="v3favorite-rooms-carousel"
        name="favorite-rooms-carousel"
      >
        <Slide
          v-for="(elem, index) in favorites"
          :key="index"
          :style="'flex-basis:' + favoritesFlexBasis + ' !important;'"
          @slide-click="favoritesNavigateToPage(index)"
        >
          <CCol
            class="v3favorite-container d-flex flex-column align-items-center justify-content-center p-1"
            :class="[current == elem.favorable.id ? 'v3active-container' : '']"
            v-if="elem.favorable && elem.favorable_type == 'App\\Models\\Room'"
          >
            <div
              :class="[
                current == elem.favorable.id ? 'v3active' : '',
                isvalid === false ? 'invalid' : '',
                'v3location-box-room'
              ]"
              @click="selectElement(elem)"
            >
              <div
                :class="`fav-icon ${
                  elem.favorable.icon && elem.favorable.icon != 0
                    ? elem.favorable.icon
                    : 'flaticon-star'
                }`"
              />
            </div>

            <span data-test-id="favorite-text-box" class="v3fav-text">
              {{ elem.favorable.name }}
            </span>
          </CCol>
          <CCol
            class="v3favorite-container d-flex flex-column align-items-center justify-content-center p-1"
            :class="[current == elem.favorable.id ? 'v3active-container' : '']"
            v-if="elem.favorable && elem.favorable_type == 'App\\Models\\User'"
          >
            <div
              :class="[
                current == elem.favorable.id ? 'v3active' : '',
                isvalid === false ? 'invalid' : '',
                { 'active invalid': isUserAvailable(elem) },
                'v3location-box-user'
              ]"
              :style="{
                backgroundImage: `url(${
                  elem.favorable.avatar
                    ? elem.favorable.avatar
                    : '/img/avatars/user-avatar.jpeg'
                })`
              }"
              @click="selectElement(elem)"
            ></div>
            <span data-test-id="favorite-text-box" class="v3fav-text">
              {{ elem.favorable.first_name + " " + elem.favorable.last_name }}
            </span>
          </CCol>
        </Slide>
        <Slide v-for="(item, index) in exptyPoistions" :key="'exmpty' + index">
          <CCol
            class="v3favorite-container d-flex flex-column align-items-center justify-content-center p-1"
          >
            <div
              data-test-id="add-favorite-plus-icon"
              class="v3location-box-room"
              @click="setActivePopUp('edit-favorites')"
            >
              <div class="fav-icon fi flaticon-plus" />
            </div>

            <span class="v3fav-text"> Add favorite Teacher/Room </span>
          </CCol>
        </Slide>
      </Carousel>
    </div>
    <div v-if="helpCenterKey">
      <HelpCenterButton
        width="17"
        height="17"
        classes="bottom-right mb-2 me-2"
        :content-key="helpCenterKey"
      />
    </div>
    <!-- Modal to manage favorites -->
    <div v-if="isEditable">
      <CModal
        :scrollable="true"
        backdrop="static"
        :visible="popup.activePopUp == 'edit-locations'"
        add-content-classes="bg-white"
      >
        <div>
          <div class="pt-2 px-2">
            <button
              class="btn bg-gradient-blue text-white rounded-circle px-2 py-1 lh-normal"
              @click="closeModal()"
            >
              x
            </button>
          </div>
          <div class="d-flex justify-content-center">
            <span class="text-black-50 text-value-xl">Edit Favorites</span>
          </div>
        </div>
        <CRow class="justify-content-center mx-n3 p-0">
          <CCol md="12" class="bg-white">
            <div class="d-flex align-items-center justify-content-center mt-4">
              <div
                data-test-id="add-remove-favorites-modal"
                class="d-flex text-center align-self-stretch flex-column align-items-center justify-content-center bg-gradient-blue py-3 px-3 rounded cursor-pointer"
                @click="setActivePopUp('edit-favorites')"
              >
                <div class="fav-modal-icon fi flaticon-plus text-white" />
                <div
                  class="d-block text-white text-value-sm ms-2 fw-bolder mt-3"
                >
                  Add/Remove <br />
                  Favorites
                </div>
              </div>
              <div
                class="d-flex text-center align-self-stretch flex-column align-items-center justify-content-center bg-gradient-blue py-3 px-3 rounded ms-2"
                @click="setActivePopUp('sort-favorites')"
              >
                <div class="fav-modal-icon fi flaticon-move text-white" />
                <div
                  class="d-block text-white text-value-sm ms-2 fw-bolder mt-3"
                >
                  Manage Order
                </div>
              </div>
            </div>
          </CCol>
        </CRow>

        <template #footer-wrapper>
          <div />
        </template>
      </CModal>

      <CModal
        :scrollable="false"
        backdrop="static"
        :visible="popup.activePopUp === 'edit-favorites'"
        add-content-classes="bg-white"
      >
        <div class="d-flex flex-column justify-content-center">
          <div class="pt-2 px-2">
            <button
              class="btn bg-gradient-blue text-white rounded-circle px-2 py-1 lh-normal"
              @click="closeModal(true)"
            >
              x
            </button>
          </div>
          <span class="text-black-50 text-value-xl text-center"
            >Select teacher / rooms</span
          >
        </div>
        <div v-if="tempList" class="w-100 d-block pt-3">
          <LazyLoadSelect
            data-test-id="add-remove-favorites-select-list"
            :classes="'mb-3'"
            :is-multiple="true"
            :selected="tempList"
            type="combined"
            :adult-pass-request-disabled="true"
            :force-clear-cache="true"
            placeholder="Select location"
            :selectable="
              (option) =>
                tempList.length < 10 - globalFavorites.length &&
                !tempList.map((e) => e.value.id).includes(option.value.id) &&
                !globalFavorites
                  .map((e) => e.favorable_id)
                  .includes(option.value.id)
            "
            @changed="
              (value) => {
                tempList = value
              }
            "
          />
        </div>
        <div
          class="d-flex flex-column justify-content-center align-items-center p-3 border-top-0"
        >
          <CAlert
            v-if="tempList.length == 10"
            class="mt-4"
            :color="'danger'"
            close-button
          >
            You have the limite of favorites. You need to delete some before you
            can add.
          </CAlert>
          <CLoadingButton
            data-test-id="add-remove-favorites-submit"
            :spinner="isLoading"
            :loading="isLoading"
            class="btn bg-gradient-blue text-white"
            @click="sumbitFavoritesAdd()"
          >
            Submit
          </CLoadingButton>
        </div>
      </CModal>
      <CModal
        class="no-content-space"
        :scrollable="true"
        backdrop="static"
        :visible="popup.activePopUp === 'sort-favorites'"
        add-content-classes="bg-white"
      >
        <div class="d-flex flex-column justify-content-center shadow">
          <div class="pt-4 px-4">
            <button
              class="btn bg-gradient-blue text-white rounded-circle px-2 py-1 lh-normal"
              @click="closeModal()"
            >
              x
            </button>
          </div>
          <span class="text-black-50 text-value-xl text-center"
            >Edit / Sort Favorites</span
          >
          <span
            class="bg-accent-blue-lighter rounded-pill text-value-xs text-center text-white py-2 mx-4 my-2 d-block"
            >Note: Student can see and sort / delete their favorite(s)
            only.</span
          >
        </div>
        <CRow class="justify-content-center p-0 mt-4 fav-table-box">
          <CCol md="12" class="bg-white">
            <div class="d-flex flex-column my-2 overflow-auto">
              <div class="w-100 row text-black-50 fw-bolder py-2 border-bottom">
                <div class="col-3 text-center" />
                <div class="col-3 text-center">Icon</div>
                <div class="col-3 text-center">Name</div>
                <div class="col-3 text-center">Action</div>
              </div>
              <draggable
                v-model="reOrderedlist"
                tag="transition-group"
                :component-data="{ name: 'fade' }"
              >
                <template #item="{ element }">
                  <div
                    :key="element + 'tg-1'"
                    class="w-100 row my-1 text-start"
                  >
                    <div
                      class="col-3 text-black-50 d-flex justify-content-center align-items-center"
                    >
                      <div class="fi flaticon-move" />
                    </div>
                    <div
                      class="col-3 d-flex justify-content-center align-items-center"
                    >
                      <div class="fav-icon-manage-cnt w-100">
                        <div
                          v-if="
                            element.favorable &&
                            element.favorable_type == 'App\\Models\\User'
                          "
                        >
                          <img
                            :src="
                              element.favorable.avatar
                                ? element.favorable.avatar
                                : '/img/avatars/user-avatar.jpeg'
                            "
                            style="height: 30px; width: 30px"
                          />
                        </div>
                        <div
                          v-if="
                            element.favorable &&
                            element.favorable_type == 'App\\Models\\Room'
                          "
                          :class="[
                            element.favorable && element.favorable.icon
                              ? element.favorable.icon
                              : 'flaticon-star'
                          ]"
                        />
                      </div>
                    </div>
                    <div
                      v-if="element.favorable"
                      class="col-3 text-black-50 text-value-sm text-start text-truncate d-flex justify-content-center align-items-center"
                    >
                      {{
                        element.favorable && element.favorable.name
                          ? element.favorable.name
                          : element.favorable.first_name +
                            " " +
                            element.favorable.last_name
                      }}
                    </div>
                    <div
                      class="col-3 d-flex justify-content-center align-items-center"
                    >
                      <div
                        v-if="!element.global"
                        class="btn-delete p-1"
                        @click="removeFavorite(element)"
                      >
                        <i class="flaticon-cancel" />
                        <span class="d-inline-block ms-1">Delete</span>
                      </div>
                    </div>
                  </div>
                </template>
              </draggable>
            </div>
          </CCol>
        </CRow>
        <div
          class="d-flex justify-content-center align-items-center p-3 border-top"
        >
          <span @click="saveOrder">
            <CLoadingButton class="btn bg-gradient-blue text-white">
              Submit
            </CLoadingButton>
          </span>
          <CButton
            class="btn ms-2 border-primary text-primary"
            @click="closeModal()"
          >
            Cancel
          </CButton>
        </div>
      </CModal>
    </div>
  </div>
</template>
<script>
import { Carousel, Slide } from "@jambonn/vue-concise-carousel"
import "@jambonn/vue-concise-carousel/lib/vue-concise-carousel.css"

import draggable from "vuedraggable"
import LazyLoadSelect from "@/v3components/shared/Form/LazyLoadSelect.vue"
import HelpCenterButton from "@/v3components/HelpCenterButton"

import { mapGetters } from "vuex"
export default {
  name: "FavoriteCarousel",
  components: {
    Carousel,
    Slide,
    draggable,
    LazyLoadSelect,
    HelpCenterButton
  },
  props: [
    "action",
    "selected",
    "isvalid",
    "isEditable",
    "classes",
    "section",
    "notManagable",
    "isGlobal",
    "helpCenterKey",
    "showUnavailables",
    "options",
    "teacher",
    "noManagable"
  ],
  emits: ["favorites-updated"],
  data() {
    return {
      search: "",
      popup: {
        activePopUp: "edit-destination"
      },
      reOrderedlist: [],
      temporatyList: [],
      isLoading: false,
      tempList: [],
      favoritesPages: 5,
      favoritesWidth: "100%",
      favoritesFlexBasis: "20%",
      navigateTo: 0
    }
  },
  computed: {
    ...mapGetters({
      fromFavorites: "favorites/fromFavorites"
    }),
    favoriteLength() {
      const length = Object.keys(this.favorites).length
      return length > 5 ? true : false
    },
    favorites() {
      return this.fromFavorites.filter((e) => e.section == this.section)
    },
    notGlobalfavorites() {
      return this.fromFavorites.filter(
        (e) => e.section == this.section && e.global == false
      )
    },
    globalFavorites() {
      return this.fromFavorites.filter(
        (e) => e.section == this.section && e.global == true
      )
    },
    favoriteIds() {
      return this.favorites.map((e) => e.favorable_id)
    },
    current() {
      return this.selected && this.selected.value && this.selected.value.id
    },
    isFavMimitReached() {
      return this.favorites.length <= 10 - this.globalFavorites.length ||
        this.temporatyList.length <= 10 - this.globalFavorites.length
        ? true
        : false
    },
    exptyPoistions() {
      return this.noManagable
        ? []
        : Array.from(
            {
              length: 10 - this.favorites.length
            },
            (_, i) => i + this.favorites.length
          )
    },
    screenWidth() {
      return window.innerWidth
    }
  },
  watch: {
    notGlobalfavorites() {
      this.updateTempList()
    }
  },
  created() {
    if (this.options) {
      this.updateTempList(this.options)
    } else {
      this.$store.dispatch("favorites/getFavorites").then(() => {
        this.updateTempList(this.favorites)
      })
    }
    this.favoritesPerPage(this.screenWidth)
    window.addEventListener("resize", this.favoritesPerPage)
  },
  methods: {
    favoritesPerPage(data) {
      const innerScreenWidth =
        data && data.currentTarget && data.currentTarget.innerWidth
          ? data && data.currentTarget && data.currentTarget.innerWidth
          : data
      const sidebar = document.getElementsByClassName("v3-the-sidebar")[0]
      const hidenSidebar = sidebar ? sidebar.classList.contains("hide") : true
      const isTeacherPassPage =
        window.location.href.indexOf("teacher-pass") > -1 ? true : false
      const isKioskPage =
        window.location.href.indexOf("kiosk") > -1 ? true : false
      if (innerScreenWidth) {
        if (innerScreenWidth < 460) {
          this.favoritesPages = 2
          this.favoritesWidth = "370px"
          this.favoritesFlexBasis = "50%"
        } else if (innerScreenWidth < 575) {
          this.favoritesPages = 3
          this.favoritesWidth = "440px"
          this.favoritesFlexBasis = "33.333%"
        } else if (innerScreenWidth > 575 && innerScreenWidth < 768) {
          this.favoritesPages = isTeacherPassPage
            ? 3
            : this.favorites.length == 4
              ? 3
              : 4
          this.favoritesWidth = !isTeacherPassPage ? "550px" : "100%"
          this.favoritesFlexBasis = isTeacherPassPage
            ? "33.333%"
            : this.favorites.length == 4
              ? "33.333%"
              : "25%"
        } else if (innerScreenWidth >= 768 && innerScreenWidth <= 992) {
          if (hidenSidebar && !isKioskPage && !isTeacherPassPage) {
            this.favoritesPages = 5
            this.favoritesWidth = "100%"
            this.favoritesFlexBasis = "20%"
          } else {
            this.favoritesPages = 4
            this.favoritesWidth = !isTeacherPassPage ? "790px" : "100%"
            this.favoritesFlexBasis = "25%"
          }
        } else if (innerScreenWidth >= 992) {
          if (hidenSidebar) {
            this.favoritesPages = 5
            this.favoritesWidth = "100%"
            this.favoritesFlexBasis = "20%"
          } else {
            if (innerScreenWidth <= 1450) {
              this.favoritesPages = 4
              this.favoritesWidth = !isTeacherPassPage ? "430px" : "100%"
              this.favoritesFlexBasis = "25%"
            } else {
              this.favoritesPages = 5
              this.favoritesWidth = "100%"
              this.favoritesFlexBasis = "20%"
            }
          }
        }
      } else {
        this.favoritesPages = 5
        this.favoritesWidth = "100%"
        this.favoritesFlexBasis = "20%"
      }
    },
    updateTempList() {
      this.tempList = this.notGlobalfavorites.map((e) => {
        return {
          capacity: true,
          label: e.favorable.name,
          value: {
            id: e.favorable.id,
            type: e.favorable_type
          }
        }
      })
    },
    selectElement(elem) {
      this.action({
        label: elem.favorable.name
          ? elem.favorable.name
          : elem.favorable.first_name + " " + elem.favorable.last_name,
        value: {
          type: elem.favorable_type,
          id: elem.favorable.id,
          comment_type: elem.favorable.comment_type,
          unavailability: Object.prototype.hasOwnProperty.call(
            elem.favorable,
            "active_unavailability"
          )
            ? elem.favorable.active_unavailability
            : null
        },
        capacity: Object.prototype.hasOwnProperty.call(
          elem.favorable,
          "capacity"
        )
          ? elem.favorable.capacity
          : null
      })
    },
    favoritesNavigateToPage(index) {
      const selectedItem = index + 1
      if (this.favoritesPages == 5) {
        this.navigateTo = selectedItem <= 5 ? 0 : 1
      }
      if (this.favoritesPages == 4) {
        this.navigateTo = selectedItem <= 4 ? 0 : selectedItem <= 8 ? 1 : 3
      }
      if (this.favoritesPages == 3) {
        this.navigateTo =
          selectedItem <= 3
            ? 0
            : selectedItem <= 6
              ? 1
              : selectedItem <= 9
                ? 2
                : 3
      }
      if (this.favoritesPages == 2) {
        this.navigateTo =
          selectedItem <= 2
            ? 0
            : selectedItem <= 4
              ? 1
              : selectedItem <= 6
                ? 2
                : selectedItem <= 8
                  ? 3
                  : 4
      }
    },
    isUserAvailable(elem) {
      return (
        this.showUnavailables &&
        elem.favorable_type == "App\\Models\\User" &&
        Object.prototype.hasOwnProperty.call(
          elem.favorable,
          "active_unavailability"
        ) &&
        elem.favorable.active_unavailability
      )
    },
    setActivePopUp(popup) {
      this.popup.isVisible = true
      this.popup.activePopUp = popup
      this.temporatyList = this.favorites.map((e) => e)
      this.reOrderedlist = this.favorites.map((e) => e)
      this.search = ""
    },
    closeModal(refreshTempList) {
      if (refreshTempList) {
        this.updateTempList()
      }
      this.popup.activePopUp = ""
    },
    removeFavorite(elem) {
      this.temporatyList = this.temporatyList.filter((e) => {
        return !(
          e.favorable_id == elem.favorable_id &&
          e.favorable_type == elem.favorable_type
        )
      })
      this.reOrderedlist = this.temporatyList
    },
    addToFavorite(elem) {
      if (!this.isInFavorite(elem) && !this.isFavMimitReached) {
        this.temporatyList.push(elem)
      } else {
        this.temporatyList = this.temporatyList.filter((e) => {
          if (e.value) {
            return !(
              e.value.id == elem.value.id && e.value.type == elem.value.type
            )
          } else {
            return !(
              e.favorable_id == elem.value.id &&
              e.favorable_type == elem.value.type
            )
          }
        })
      }
    },
    sumbitFavorites() {
      const favForSubmit = this.temporatyList.map((e, i) => {
        if (e.value) {
          return {
            id: e.value.id,
            type: e.value.type,
            position: i,
            global: e.value.global
          }
        } else {
          return {
            id: e.favorable_id,
            type: e.favorable_type,
            position: i,
            global: e.global
          }
        }
      })
      this.$store
        .dispatch("favorites/updateFavoriteBulk", {
          elements: favForSubmit,
          section: this.section,
          global: this.isGlobal
        })
        .then(() => {
          this.temporatyList = []
          this.$emit("favorites-updated", true)
          this.updateTempList()
          this.closeModal()
        })
    },
    sumbitFavoritesAdd() {
      const favForSubmit = this.tempList.map((e, i) => {
        if (e.value) {
          return {
            id: e.value.id,
            type: e.value.type,
            position: i,
            global: e.value.global
          }
        } else {
          return {
            id: e.favorable_id,
            type: e.favorable_type,
            position: i,
            global: e.global
          }
        }
      })
      this.$store
        .dispatch("favorites/updateFavoriteBulk", {
          elements: favForSubmit,
          section: this.section,
          global: this.isGlobal
        })
        .then(() => {
          this.temporatyList = []
          this.$emit("favorites-updated", true)
          this.updateTempList()
          this.closeModal()
        })
    },
    isInFavorite(elem) {
      return this.temporatyList.find((e) => {
        if (e.value) {
          return e.value.id == elem.value.id && e.value.type == elem.value.type
        } else {
          return (
            e.favorable_id == elem.value.id &&
            e.favorable_type == elem.value.type
          )
        }
      })
    },
    saveOrder() {
      const favForSubmit = this.reOrderedlist.map((e, i) => {
        if (e.value) {
          return {
            id: e.value.id,
            type: e.value.type,
            position: i,
            global: e.value.global
          }
        } else {
          return {
            id: e.favorable_id,
            type: e.favorable_type,
            position: i,
            global: e.global
          }
        }
      })
      this.$store
        .dispatch("favorites/updateFavoriteBulk", {
          elements: favForSubmit,
          section: this.section,
          global: this.isGlobal
        })
        .then(() => {
          this.temporatyList = []
          this.$emit("favorites-updated", true)
          this.updateTempList()
          this.closeModal()
        })
    }
  }
}
</script>
<style lang="scss">
.drag-cnt {
  margin-bottom: 10px;
  max-width: 100px;
}
.fav-table-box {
  overflow-y: auto;
}
</style>
